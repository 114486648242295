<template>
    <ion-page>
        <ThanhTieuDeVue TieuDe="Phương tiên đã vận hành"
            ><template #after><div></div></template
        ></ThanhTieuDeVue>
        <ion-content>
            <DxList
                :data-source="data"
                height="calc(100% - 125px)"
                page-load-mode="scrollBottom"
                key-expr="id"
                :hoverStateEnabled="false"
                :focusStateEnabled="false"
                :activeStateEnabled="false"
                no-data-text="Không có dữ liệu!"
                class="list-style pt-2"
            >
                <!-- selection-mode="multiple" -->
                <template #item="{ data: item }">
                    <div>
                        <ItemPhuongTienDaVanHanhVue :item="item" />
                    </div> </template
            ></DxList> </ion-content
    ></ion-page>
</template>
<script>
import ThanhTieuDeVue from "../../src/components/_Common/ThanhTieuDe";
import ItemPhuongTienDaVanHanhVue from "../../src/components/ItemPhuongTienDaVanHanh";
import PhuongTien from "../../class/PhuongTien";
import { DxList } from "devextreme-vue";
import { IonPage, IonContent } from "@ionic/vue";
export default {
    layout: "application",
    components: {
        ThanhTieuDeVue,
        ItemPhuongTienDaVanHanhVue,
        DxList,
        IonPage,
        IonContent,
    },
    props: {},
    data() {
        return {
            data: [],
        };
    },
    methods: {
        getData() {
            this.data = new PhuongTien().layDSPhuongTienDaVanHanh();
        },
    },
    created() {},
    mounted() {
        this.getData();
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
.dx-template-wrapper.dx-item-content.dx-list-item-content {
    padding-top: unset;
    padding-bottom: 12px;
}
</style>