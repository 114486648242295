<template>
    <div class="card container-md fluid">
        <div class="row pb-2 justify-space-between align-center">
            <div>Biển kiểm soát</div>
            <div class="pl-2">{{ item.bienKiemSoat }}</div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div>Loại xe</div>
            <div class="pl-2">{{ item.loaiXe }}</div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div>Số ghế | Số giường</div>
            <div class="pl-2">{{ `${item.soGhe} | ${item.soGiuong}` }}</div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div>Ngày vận hành đầu</div>
            <div class="pl-2">
                {{ moment(item.ngayVanHanhDau).format("DD/MM/yyyy") }}
            </div>
        </div>
        <div class="row justify-space-between align-center">
            <div>Ngày vận hành cuối</div>
            <div class="pl-2">
                {{ moment(item.ngayVanHanhCuoi).format("DD/MM/yyyy") }}
            </div>
        </div>
    </div>
</template>
<script>
import PhuongTien from "../../class/PhuongTien";
export default {
    props: {
        item: {
            type: Object,
            default: () => new PhuongTien().get(),
        },
    },
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
.card {
    border-radius: $border_radius;
    box-shadow: $box_shadow;
}
</style>